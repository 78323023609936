import React, { useMemo, useState } from "react"
import { FormattedMessage } from "react-intl"
import Layout from "../components/Layout"
import Link from "../components/Link"

import "../styles/tour.scss"

export default function Lecture({ location: gatsbyLocation, pageContext }) {
  const lectures = useMemo(
    () =>
      typeof window !== "undefined"
        ? JSON.parse(window?.localStorage.getItem("lectures")).filter(
            lectures => lectures.lng === pageContext.language
          )
        : [],
    []
  )
  const lecture = useMemo(
    () => gatsbyLocation?.state?.tour || lectures[0],
    [gatsbyLocation?.state?.tour]
  )

  const [activeDate, setActiveDate] = useState(lecture?.dates[0])
  const [activeImg, setActiveImg] = useState(lecture?.imgs[0]?.url)

  const getDateBoxes = useMemo(
    () =>
      activeDate?.time ? (
        <div className="dates-container">
          {lecture?.dates.map(date => (
            <div
              key={date.time}
              onClick={() => setActiveDate(date)}
              className={`date-cube ${
                date.time === activeDate?.time ? "active" : ""
              }`}
            >
              {new Date(date.time).toLocaleDateString("en-GB")}
            </div>
          ))}
        </div>
      ) : null,
    [activeDate?.time]
  )

  const getDateInfo = useMemo(() => {
    const minutes = new Date(activeDate?.time).getMinutes()
    return activeDate?.time ? (
      <>
        <p>
          <b>
            <FormattedMessage id="lecture_lecture-begins" />:
          </b>{" "}
          {new Date(activeDate.time).getHours()}:
          {minutes < 10 ? `0${minutes}` : minutes}
        </p>
        <p>
          <b>
            <FormattedMessage id="lecture_location" />:
          </b>{" "}
          {activeDate.location}
        </p>
        <p>
          <b>
            <FormattedMessage id="tour_cost" />:
          </b>{" "}
          {activeDate.cost}
        </p>
        <p>
          <b>
            <FormattedMessage id="tour_registration" />:
          </b>{" "}
          {activeDate.register}
        </p>
        <br />
        <br />
        <Link
          href={"/register"}
          state={{ event: { ...activeDate, ...lecture } }}
          className="tour-cta"
        >
          <FormattedMessage id="tour_register-now" />
        </Link>
      </>
    ) : null
  }, [activeDate?.time, activeDate?.cost, activeDate?.register])

  const getImgsRow = useMemo(
    () =>
      lecture?.imgs.length > 1 ? (
        <div className="imgs-row">
          {lecture?.imgs.map((img, i) => (
            <img
              onClick={() => setActiveImg(img.url)}
              key={img.url}
              src={img.url}
              alt={`lecture ${i}`}
              className={`${img.url === activeImg ? "active" : ""}`}
            />
          ))}
        </div>
      ) : null,
    [lecture?.imgs, activeImg]
  )

  return (
    <Layout pageContext={pageContext}>
      <div className="page-banner lecture-banner">
        <div className={`page-banner-content ${pageContext.language}`}>
          <h1>
            {" "}
            <FormattedMessage id="global_lectures" />
          </h1>
        </div>
      </div>
      <div className="tour-page">
        <div className="tour-details">
          <h3>{lecture?.title}</h3>
          <h4>{lecture?.subtitle}</h4>
          {getDateBoxes}
          <p className="content">{lecture?.content}</p>
          {getDateInfo}
        </div>
        <div className="imgs-container">
          <img
            className="active-img"
            src={activeImg}
            alt="lecture-active-img"
          />
          {getImgsRow}
        </div>
      </div>
    </Layout>
  )
}

export { Head } from "../components/Head"
